import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import GuideSider from "../../components/guide/guide-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class OperationHelp extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Planner Guide - Operation guide describes how  everything in DayViewer works together"
            />
            <title>DayViewer Online Planner Guide - Operation</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <GuideSider />
            <Layout className="guide-body">
              <h1>Operation Guide</h1>
              <Row>
                <Col>
                  <Collapse>
                    <Panel header="Creating A Calendar Entry" key="1">
                      <p>
                        There are several ways in which calendar entries may be
                        created. The easiest is to go to the Calendar Page and
                        enter a Title, Start Time &amp; Duration in the Quick
                        Entry bar - then click the Add button to create the
                        entry.
                      </p>
                      <p>
                        You can also click inside the day cell of any date
                        inside the calendar and the Add Entry form will appear,
                        simply fill this out as required and click Save
                      </p>
                      <p>
                        Entries will appear in the Calendar and any section of
                        DayViewer which displays Calendar Entries (eg. Timelines
                        and Tables)
                      </p>
                    </Panel>
                    <Panel
                      header="Creating,Updating and Deleting Any Entry Type"
                      key="2"
                    >
                      <p>
                        To create any entry type eg Note, Journal, Contact,
                        Payment or Task, or to open the main Entry form in the
                        Calendar simply click on the ADD in the toolbar. This
                        will open a form from the right edge of the screen. On
                        mobile handsets this will fill the screen.
                      </p>
                      <p>
                        {" "}
                        To remove any entry - simply click to view that entry
                        and there should be a delete button at the bottom of the
                        form. In all grid views, each entry card will have its
                        own delete button.
                      </p>
                      <p>
                        To update any entry click on the edit icon in the grid
                        view or if you are on the calendar, simply click on the
                        item to bring up the edit entry form - this will appear
                        from the right edge of the screen (full screen if using
                        mobile){" "}
                      </p>
                    </Panel>
                    <Panel header="Task Management" key="3">
                      <p>
                        {" "}
                        Create tasks quickly and intuitively within Tasks
                        section.
                      </p>

                      <p>
                        Enter a Title in the title field of the quick entry form
                        and then select a Start Time and duration. After
                        clicking the "Add" button, the task will be generated.
                      </p>

                      <p>
                        It is recommended to further update the details on your
                        task by clicking on the title in the table, this will
                        take you to the Edit controls for that task. You will
                        see a section here specifically relating to tasks.
                      </p>

                      <p>
                        Tasks have Priority (Normal, Low, High) and Progress
                        indicator if there is a checklist
                      </p>

                      <p>
                        With Pro subscription tasks can have file attachments or
                        images, can be recurred and made shareable.
                      </p>
                    </Panel>
                    <Panel header="Events" key="4">
                      <p>
                        {" "}
                        Events are designed for organizing meetings, internal
                        events and meetups with people you know, or recognize
                        you.
                      </p>

                      <p>
                        If you wish to send email invites, you need to create
                        those contacts prior into your contacts area with at
                        least the name and email field filled out.
                      </p>

                      <p>
                        Currently there are two event types: Location &amp;
                        Virtual. Location based are physical "in real life" type
                        events and Virtual are a space for linking your video
                        meetings, online conferences and similar.
                      </p>

                      <p>
                        The process can be summarized as: Create the event, add
                        contacts, make a shareable link and distribute that link
                        to the people you wish to invite.
                      </p>
                      <p>
                        An email will be sent should you choose to send out an
                        email of that event from the{" "}
                        <strong>Events Cards</strong> section. Filter /search
                        that card and click Send Invites button. If you have a
                        large amount of information in the description, create a
                        shareable link and that will also be sent with the email
                        invite.
                      </p>

                      <p>
                        Below we have a video explainer of the events section
                        which shows the operation and creating a shareable link.
                      </p>

                      <div>
                        <video
                          preload="none"
                          controls="controls"
                          // poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                          // muted="muted"
                          // autoplay="autoplay"
                          // loop="loop"
                          width="100%"
                          name="Online Events System"
                          src="https://dayviewer.s3.us-west-2.amazonaws.com/img/home/dvnext/videos/dv-event-explainer.mp4"
                        />
                      </div>
                    </Panel>
                    <Panel header="Reminder Notifications" key="5">
                      <p>
                        Reminders can be set either by default in your Account
                        section under Settings, or by Adding/editing your
                        calendar entries. Calendar Entries, Tasks and Payments
                        may have reminders set.
                      </p>
                      <p>
                        Notifications may be pushed to your phone or browser
                        (requires permission) or to the email address associated
                        with your account.
                      </p>
                    </Panel>
                    <Panel header="Journal Entries" key="6">
                      <p>
                        Journal entries are suitable for longer documents or
                        articles. Create by entering a title in the form, or by
                        using the + Button. Use the editor to format the text as
                        required.
                      </p>
                    </Panel>
                    <Panel header="Note Entries" key="7">
                      <p>
                        Note entries are suitable for memos, checklists and for
                        pasting content you might come across on while browsing.
                        Create by entering a title in the form, or by using the
                        + Button.{" "}
                      </p>
                      <p>
                        Notes can be pushed to calendar entries providing an
                        easy way to add repetitive information to your calendar.
                      </p>
                    </Panel>
                    <Panel header="Label Manager and Labels Page" key="8">
                      <p>
                        Any labels you create or have created directly from
                        within any entry will be listed here.
                      </p>
                      <p>
                        You will be able to delete or add labels - but be warned
                        if you delete a label with entries already attached -
                        then they will all be removed.
                      </p>
                      <p>
                        Clicking on a label in the labels page will bring up all
                        entries associated with that label
                      </p>
                    </Panel>
                    <Panel header="Recur (Repeat) Entries" key="9">
                      <p>
                        The recur feature allows you to copy a calendar, task or
                        payment entry according to your selection. Simply select
                        all the dates you wish that entry to recur.
                      </p>
                      <p>
                        Alternatively in table or card grid views you can copy
                        entries and change the copied entry date/time
                      </p>
                    </Panel>
                    <Panel header="Contact Entries" key="10">
                      <p>
                        Simply, add name and email as a minimum. Further details
                        can be filled in thereafter. Use as an address book and
                        for quick referral, contacts may be labelled and have
                        reference notes.
                      </p>
                    </Panel>
                    <Panel header="Payment Entries" key="11">
                      <p>
                        Keep a track of payments coming in or expenses going out
                        using this simple payments system. It's a great way to
                        keep to budget or to remind you of when a payment needs
                        to be made. Payments show up on the calendar and can
                        conversely be created from within a calendar entry
                        itself by clicking on the More button and selecting
                        payment.{" "}
                      </p>
                      <p>Pro users can view a summary in their dashboard</p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default OperationHelp;
